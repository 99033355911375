import { Component, OnInit, Inject } from '@angular/core';
import { SessionCartService } from '../../event/session-cart/session-cart.service';
import { ActivatedRoute } from '@angular/router';
import { RegistrationServiceFactory } from '../registration-service.factory';
import { LabelsService } from '../../../services/labels.service';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DateFormatter } from 'src/app/helpers/DateFormatter';
import { Event } from 'src/app/models/Event';
import { LocalizableError } from 'src/app/models/LocalizableError';
import { ActiveEventService } from 'src/app/services/active-event.service';
import { EVENT_SERVICE } from './../../../providers/service.providers';
import { EventService } from '../../../services/event.service';
import { INVITATION_TYPES } from '../../home/constants';

@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
    private readableEventId: string;
    public name: string
    public company: string
    public email: string
    public event: Event
    public error: LocalizableError
    public otherEvents: Event[]

    constructor(
        @Inject(EVENT_SERVICE) private eventService: EventService,
        private route: ActivatedRoute,
        private cartService: SessionCartService,
        private labelsService: LabelsService,
        private registrationServiceFactory: RegistrationServiceFactory,
        private datePipe: DatePipe,
        private activeEventService: ActiveEventService,
    ) {}

    ngOnInit() {
        this.readableEventId = this.route.snapshot.queryParams['id'];
        
        this.name = this.route.snapshot.queryParams['name'];
        this.email = this.route.snapshot.queryParams['email'];
        this.company = this.route.snapshot.queryParams['company'];

        this.cartService.flushCart(this.readableEventId);
        this.registrationServiceFactory.dispose(this.readableEventId);
        this.getEventDetails()
    }

    public translateLabel(translationKey: string, defaultValue: string): Observable<string> {
        return this.labelsService.translateLabel(translationKey, defaultValue);
    }
    
    public formatDate(type: string, event?: Event) : string {
        let dateSettings = DateFormatter.getDateSettings();

        if (type == 'currentEvent'){
            if (dateSettings.convertToLocalDate) {
                return DateFormatter.formatDate(this.datePipe, this.event.startDateUTC);
            }
            else{
                return DateFormatter.formatDate(this.datePipe, this.event.startDate, this.event.timeZone);
            }
        } else if (type == 'otherEvents') {
            if (dateSettings.convertToLocalDate) {
                return DateFormatter.formatRangedDate(this.datePipe, event.startDateUTC, event.endDateUTC);
            }
            else {
                return DateFormatter.formatRangedDate(this.datePipe, event.startDate, event.endDate, event.timeZone);
            }
        }
    }

    private async getEventDetails() : Promise<void> {
        try {
            this.event = await this.activeEventService.getEvent(this.readableEventId).toPromise();
            this.loadEvents()
        } catch (error) {
            this.error = error;
            console.error(error);
        }
    }

    private loadEvents() {
        this.eventService.getPublishedEvents().subscribe(
            events => {
                if (events && this.event) {
                    const filteredEvents = events.filter(ev => ev.eventId !== this.event.eventId)
                    const futurePublicEvents = this.removePrivateEvents(filteredEvents)
                    this.otherEvents = futurePublicEvents.slice(0,3)
                }
            },
            (error: LocalizableError) => this.handleErrorResponse(error)
        );
    }

    private handleErrorResponse(error: LocalizableError) {
        this.error = error;
    }

    removePrivateEvents (event: Event[]) : Event[] {
        const currentDate = new Date()

        return event.filter((eventRow) => {
            //remove past events first
            const endDate = new Date(eventRow.endDateUTC)
            if (endDate >= currentDate) {
                const invitationType = eventRow.customFields.cr035_invitationtype
                return invitationType ? invitationType.Value !== INVITATION_TYPES.private : true
            }
        } );
    }
}
