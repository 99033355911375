import { EVENT_SERVICE } from './../../providers/service.providers';
import { EventService } from '../../services/event.service';
import { Component, Inject, OnInit } from '@angular/core';
import { Event } from '../../models/Event';
import { LocalizableError } from 'src/app/models/LocalizableError';
import { debounceTime } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { LabelsService } from 'src/app/services/labels.service';
import { TimeDisplayConfig } from '../../../assets/config/config';
import { DatePipe } from '@angular/common';
import { DateFormatter } from 'src/app/helpers/DateFormatter';
import { LOCATION_FILTERS, TYPE_FILTERS, BUSINESS_UNIT_FILTERS, SERIES_FILTERS, INVITATION_TYPES } from './constants';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    public allEvents: Event[];
    public filteredEvents: Event[];
    public isLoading: boolean;
    public error?: LocalizableError;
    private subject: Subject<string> = new Subject();
    private timeDisplayConfig: any;
    private searchText: string = ''
    public locationFilterName: string
    public locationFilterCode: number = 0
    public locationSelectedIndex: number
    public locationFilters: Array<object>
    public typeFilterName: string
    public typeFilterCode: number = 0
    public typeSelectedIndex: number
    public typeFilters: Array<object>
    public unitFilterName: string
    public unitFilterCode: number = 0
    public unitSelectedIndex: number
    public unitFilters: Array<object>
    public seriesFilterName: string
    public seriesFilterCode: number = 0
    public seriesSelectedIndex: number
    public seriesFilters: Array<object>

    constructor(
        @Inject(EVENT_SERVICE) private eventService: EventService,
        private labelsService: LabelsService,
        private datePipe: DatePipe) {
    }

    ngOnInit(): void {
        this.timeDisplayConfig = TimeDisplayConfig;
        this.locationFilters = LOCATION_FILTERS
        this.typeFilters = TYPE_FILTERS
        this.unitFilters = BUSINESS_UNIT_FILTERS
        this.seriesFilters = SERIES_FILTERS
        this.loadPublishedEvents();
        this.subject.pipe(
            debounceTime(300)
        ).subscribe(searchTextValue => {
            this.handleSearch(searchTextValue);
        });
    }

    private loadPublishedEvents() {
        this.isLoading = true;
        this.eventService.getPublishedEvents().subscribe(
            events => {
                this.allEvents = this.removePrivateEvents(events);
                this.filteredEvents = this.removePrivateEvents(this.sortEventsByDate('oldest', events))
                this.isLoading = false;
            },
            (error: LocalizableError) => this.handleErrorResponse(error)
        );
    }

    private handleErrorResponse(error: LocalizableError) {
        this.error = error;
        this.isLoading = false;
    }

    public translateLabel(translationKey: string, defaultValue: string): Observable<string> {
        return this.labelsService.translateLabel(translationKey, defaultValue);
    }

    private getAreaLabel(event: Event): string {
        let date = this.getDateString(event);
        let building = event.building ? `at ${event.building.name}` : "";
        return `${event.eventName} on ${date} ${building}`;
    }

    public getDateString(event: Event): string {
        let dateSettings = DateFormatter.getDateSettings();

        if(dateSettings.convertToLocalDate){
            return DateFormatter.formatRangedDate(this.datePipe, event.startDateUTC, event.endDateUTC);
        }
        else{
            return DateFormatter.formatRangedDate(this.datePipe, event.startDate, event.endDate, event.timeZone);
        }
    }

    handleSearch(searchText: string) {
        if (searchText) {
            this.searchText = searchText.toLocaleLowerCase();
        }
        this.filterEvents(null, 'search', null, null)
    }

    search(searchText: string) {
        this.subject.next(searchText);
    }

    orderEvents(order: string) {
        if (order === "date-asc") {
            this.sortEventsByDate('oldest', this.filteredEvents)
        } else if (order === "date-desc") {
            this.sortEventsByDate('newest', this.filteredEvents)
        } else if (order === "name-asc") {
            this.filteredEvents.sort((a, b) => a.eventName.localeCompare(b.eventName));
        } else if (order === "name-desc") {
            this.filteredEvents.sort((a, b) => b.eventName.localeCompare(a.eventName));
        }
    }

    sortEventsByDate (sortBy: 'newest' | 'oldest', event: Event[]) : Event[] {
        if(sortBy === 'newest'){
            return event.sort((a, b) => { return <any>new Date(b.startDate.toString()) - <any>new Date(a.startDate.toString()) })
        } else if(sortBy === 'oldest'){
           return event.sort((a, b) => { return <any>new Date(a.startDate.toString()) - <any>new Date(b.startDate.toString()) });
        }
    }

    filterEvents(index: number, filterType: string, name: string, code:number) {
        if (filterType !== 'search') {
            this[`${filterType}FilterName`] = name
            this[`${filterType}FilterCode`] = code
            this[`${filterType}SelectedIndex`] = index
        }
        const filters = {
            location: this.locationFilterCode,
            type: this.typeFilterCode,
            unit: this.unitFilterCode,
            series: this.seriesFilterCode,
        }

        this.filteredEvents = this.allEvents.filter((event: Event) => {
            let locationIncluded: boolean, typeIncluded: boolean, unitIncluded: boolean, seriesIncluded: boolean
            locationIncluded = typeIncluded = unitIncluded = seriesIncluded = false
            if (event.eventName.toString().toLocaleLowerCase().includes(this.searchText) || this.searchText == '') {
                for (const key in filters) {
                    if (key == 'location') {
                        if (event.customFields.new_eventlocation) {
                            locationIncluded = event.customFields.new_eventlocation['Value'] === filters[key] || filters[key] === 0
                        } else {
                            locationIncluded =  filters[key] === 0
                        }
                    }
                    if (key == 'type') {
                        typeIncluded = event.eventType === filters[key] || filters[key] === 0
                    }
                    if (key == 'unit') {
                        if (event.customFields.cr035_businessunit) {
                            unitIncluded = event.customFields.cr035_businessunit['Value'] === filters[key] || filters[key] === 0
                        } else {
                            unitIncluded =  filters[key] === 0
                        }
                    }
                    if (key == 'series') {
                        seriesIncluded = event.customFields.msdyncrm_sessionscount > 0 || filters[key] === 0
                    }
                }
                return (locationIncluded && typeIncluded && unitIncluded && seriesIncluded) ? true : false
            }
        })
        this.filteredEvents = this.sortEventsByDate('oldest', this.filteredEvents)
    }

    removePrivateEvents (event: Event[]) : Event[] {
        const currentDate = new Date()

        return event.filter((eventRow) => {
            //remove past events first
            const endDate = new Date(eventRow.endDateUTC)
            if (endDate >= currentDate) {
                const invitationType = eventRow.customFields.cr035_invitationtype
                return invitationType ? invitationType.Value !== INVITATION_TYPES.private : true
            }
        } );
    }
}
