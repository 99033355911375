import { Component, OnInit, Input, Directive, ElementRef, NgZone} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ConfirmationDetails } from '../../../models/ConfirmationDetails';
import { LabelsService } from '../../../services/labels.service';
import { IFormHandler } from '../eventregistration.component';
declare var MsCrmMkt:any;

/**
 * This directive responsible for :
 *  - Moving the marketing forms script outside of the Angular Framework scope
 *  - Capturing and initializing the Marketing forms loader
 */
@Directive({ selector: '[registration-form-initializer]' })
export class RegistrationFormInitializerDirective implements OnInit {
    @Input() public formHandler: IFormHandler;
    @Input() public userData: Object;
    public confirmationDetails: ConfirmationDetails
    @Input() isAuthCallDone: boolean

    constructor(private elementRef: ElementRef,
                private labelsService: LabelsService,
                private ngZone: NgZone,) { this.confirmationDetails = {} }

    ngOnInit(): void {
      setTimeout(() => {
        this.reinsertScripts();
      })
    }

    reinsertScripts(): void {
        const externalScriptId = "external-scripts";
        const scripts = <HTMLScriptElement[]>this.elementRef.nativeElement.getElementsByTagName('script');
        const scriptsInitialLength = scripts.length;

        var externalScripts = document.getElementById(externalScriptId);
        if(!externalScripts){
          externalScripts = document.createElement("div");
          externalScripts.id = externalScriptId;
          document.body.appendChild(externalScripts);
        }
        
        externalScripts.innerHTML = "";
        this.resetMsCrmMkt();
        window["d365mktConfigure"] = this.d365mktConfigure.bind(this);
        
        for (let i = 0; i < scriptsInitialLength; i++) {
            const script = scripts[i];
            const scriptCopy = <HTMLScriptElement>document.createElement('script');
            scriptCopy.type = script.type ? script.type : 'text/javascript';
            scriptCopy.async = false;

            if (script.innerHTML) {
              scriptCopy.innerHTML = script.innerHTML;
            } else if (script.src) {
              scriptCopy.src = script.src;
            }
            document.getElementById(externalScriptId).appendChild(scriptCopy);
            scripts[i].remove();
        }
    }

    resetMsCrmMkt(){
      try {
        MsCrmMkt = null;
      } catch {
        /* Disolve exception */
      }
    }

    public d365mktConfigure = function(){
      if(MsCrmMkt) {
        const currentLanguageCode = this.labelsService.getCurrentLanguageCode();
        const _this = this;
        MsCrmMkt.MsCrmFormLoader.uiLanguage = currentLanguageCode;

        MsCrmMkt.MsCrmFormLoader.off("afterFormSubmit");
        MsCrmMkt.MsCrmFormLoader.on("afterFormSubmit", function(event: any) {
          _this.getFormValues()
          _this.formHandler.onSubmitSuccessfull(event, _this.confirmationDetails)
        });

        MsCrmMkt.MsCrmFormLoader.off("formLoad");
        MsCrmMkt.MsCrmFormLoader.on("formLoad", function(event: any) {
          _this.formHandler.onFormRegistrationLoad(event);
        });

        MsCrmMkt.MsCrmFormLoader.off("afterFormLoad");
        MsCrmMkt.MsCrmFormLoader.on("afterFormLoad", function(event: any) {
          _this.formHandler.afterFormRegistrationLoaded(event);
          _this.populateFormFields()
        });
        
        MsCrmMkt.MsCrmFormLoader.off("formSubmit");
        MsCrmMkt.MsCrmFormLoader.on("formSubmit", (event: any) => {
          _this.formHandler.onSubmitEvent(event);
        });
      }

      return false;
    };

    populateFormFields(): void {
      this.ngZone.run(() => {
        if (this.isAuthCallDone && this.userData) {
          setTimeout(() => {
              const formFields = [
                { fieldName: 'firstName', value: this.userData['firstName'] || '' },
                { fieldName: 'lastName', value: this.userData['lastName'] || '' },
                { fieldName: 'email', value: this.userData['email'] || '' },
                { fieldName: 'mobile', value: this.userData['mobile'] || '' },
                { fieldName: 'dietaryRequirements', value: this.userData['dietaryRequirements'] || '' },
              ]
              formFields.forEach(form => {
                const selectedElement = document.querySelector<HTMLInputElement>(`input[prefill-field-name='${form.fieldName}']`)
                if (selectedElement) {
                  selectedElement.value = form.value
                }
              })
          })
        }
      })
    }

    // Retrieve form values from HTML Input DOM and populates to router queryParams as strings
    // Undefined value will be "undefined" in URL query string
    getFormValues(): void {
        const fields = ['firstName', 'lastName', 'email', 'company']
        fields.forEach(field => {
            const form = document.querySelector<HTMLInputElement>(`input[prefill-field-name='${field}']`);
            this.confirmationDetails[field] = form ? form.value : '';
        });
    }
}

@Component({
    selector: '[event-registration-form]',
    templateUrl: './eventregistrationform.component.html'
})
export class EventRegistrationFormComponent implements OnInit{
    @Input() public eventmarketingformhtml: SafeHtml;
    @Input() public formHandler: IFormHandler;
    @Input() public userData: Object;
    @Input() isAuthCallDone: boolean

    ngOnInit(): void {
    }
}

