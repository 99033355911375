export const LOCATION_FILTERS = [
    { value: 0, label: 'All Locations'},
    { value: 100000000, label: 'NSW'},
    { value: 100000001, label: 'QLD'},
    { value: 100000002, label: 'VIC'},
    { value: 100000003, label: 'SA'},
    { value: 100000004, label: 'WA'},
    { value: 100000005, label: 'National'},
    { value: 100000006, label: 'Online'},
]

export const TYPE_FILTERS = [
    { value: 0, label: 'All Types'},
    { value: 100000002, label: 'Conference'},
    { value: 100000004, label: 'Workshop'},
    { value: 100000005, label: 'Webinar'},
    { value: 293760001, label: 'Face to Face Event'},
]

export const BUSINESS_UNIT_FILTERS = [
    { value: 0, label: 'All Topics'},
    { value: 293760000, label: 'Connective'},
    { value: 293760001, label: 'CAF'},
    { value: 293760002, label: 'CHL'},
    { value: 293760003, label: 'Compliance'},
    { value: 293760004, label: 'Residential'},
    { value: 293760005, label: 'Commercial'},
    { value: 293760006, label: 'Other'},
]

export const SERIES_FILTERS = [
    { value: 0, label: 'All'},
    { value: 1, label: 'Series'},
]

export const INVITATION_TYPES = {
    private: 293760001
}
