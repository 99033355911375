import { HTTP_HELPER } from '../../providers/http.helper.provider';
import { SessionService } from 'src/app/services/session.service';
import { environment } from '../../../environments/environment';
import { HttpHelper } from '../../helpers/HttpHelper';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { DynamicsService } from '../dynamics.service';

@Injectable()
export class DynamicsRestService implements DynamicsService {
    
    constructor(@Inject(HTTP_HELPER) private http: HttpHelper) {
    }

    public registerForDynamicsEvent(associateId: string, eventName: String, name: String, email: String): Observable<Object> {
        return this.http.post<Object>(
            `${environment.dynamicsApiEndpoint}&associateId=${associateId}&eventName=${eventName}&name=${name}&email=${email}`,
            null,
            false
        );
    }
}
