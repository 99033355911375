import { HTTP_HELPER } from '../../providers/http.helper.provider';
import { environment } from '../../../environments/environment';
import { HttpHelper } from '../../helpers/HttpHelper';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { CRMService } from '../crm.service';

@Injectable()
export class CRMRestService implements CRMService {

    constructor(@Inject(HTTP_HELPER) private http: HttpHelper) {
    }

    public getUserDetails(idToken: string): Observable<Object> {
        return this.http.post<Object>(
            `${environment.userDetailsEndpoint}/session/login`, `application=crm&useExisting=true`, true, idToken
        );
    }

    public getPreferences(idToken: string): Observable<Object> {
        return this.http.get<Object>(
            `${environment.dynamicsPreferencesEndpoint}`, true, idToken 
        );
    }
}
