import {
    DYNAMICS_SERVICE,
    EVENT_SERVICE,
    CRM_SERVICE,
} from "./../../providers/service.providers";
import { EventService } from "../../services/event.service";
import {
    Component,
    OnInit,
    Inject,
    OnDestroy
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Event } from "../../models/Event";
import { ImageHelper } from "../../helpers/ImageHelper";
import { environment } from "./../../../environments/environment";
import { LabelsService } from "src/app/services/labels.service";
import { SessionCartService } from "./session-cart/session-cart.service";
import { Session } from "src/app/models/Session";
import { ActiveEventService } from "src/app/services/active-event.service";
import { LocalizableError } from 'src/app/models/LocalizableError';
import { EventCheckoutRoutePaths } from "../eventregistration/eventregistration.component";
import { SessionTrack } from "../../models/SessionTrack";
import { Speaker } from "../../models/Speaker";
import { Pass } from '../../models/Pass';
import { time } from 'console';
import { MsTimeZoneIndexValues } from "../../constants/MSTimeZoneIndexValues";
import { TimeDisplayConfig } from '../../../assets/config/config';
import { DatePipe } from '@angular/common';
import { DefaultDateFormats } from "../../constants/DefaultDateFormats"
import { DateFormatter } from 'src/app/helpers/DateFormatter';
import auth0 from 'auth0-js';
import { DynamicsService } from "src/app/services/dynamics.service";
import { CurrentUser } from "src/app/models/CurrentUser";

@Component({
    selector: "app-event",
    templateUrl: "./event.component.html",
    styleUrls: ["./event.component.scss"]
})
export class EventComponent implements OnInit {
    public EventDetailSections = EventDetailSections;
    public readableEventId: string;
    public event: Event;
    public sessions: Session[];
    public showSessions: Boolean;
    public showSpeakers: Boolean;
    public showSessionTracks: Boolean;
    public showPasses: Boolean;
    public selectedSection: EventDetailSections = EventDetailSections.Sessions;
    public isSessionCartCollapsed: Boolean = false;
    public isSessionCartAllowed: Boolean = false;
    public isLoadingData = false;
    public error: LocalizableError;
    public formattedDate: string;
    public currentUser: CurrentUser;
    public isAuthCallDone: Boolean;
    private defaultImageUrlSelfHosted = 'default-event-image.jpg';
    private defaultImageUrlCrmHosted = '../../../assets/images/homebanner2024.png'

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        @Inject(EVENT_SERVICE) private eventService: EventService,
        private imageHelper: ImageHelper,
        private labelsService: LabelsService,
        private sessionCartService: SessionCartService,
        private activeEventService: ActiveEventService,
        private datePipe: DatePipe,
        @Inject(DYNAMICS_SERVICE) private dynamicsService: DynamicsService
    ) {}

    ngOnInit() {

        this.route.queryParamMap.subscribe(paramMap => {
            this.readableEventId = paramMap.get(
                EventQueryParameterNames.ReadableEventId
            );

            this.getEventAndSessionData().then(() =>
                this.setIsSessionCartAllowed()
            );
        });

        this.eventService.getSessionTracks(this.readableEventId).subscribe(
            tracks => {
                this.showSessionTracks = tracks && tracks.length > 0;
                this.chooseRoute();
        });

        this.eventService.getSpeakers(this.readableEventId).subscribe(
            speakers => {
                this.showSpeakers = speakers && speakers.length > 0;
                this.chooseRoute();
        });

        this.eventService.getPasses(this.readableEventId).subscribe(
            passes => {
                this.showPasses = passes && passes.length > 0;
                this.chooseRoute();
        });

        this.sessionCartService.isOpen = false;
    }

    public registerInDynamics() {
        this.dynamicsService.registerForDynamicsEvent(this.currentUser.username, this.event.eventName, this.currentUser.name, this.currentUser.email).subscribe(
            result => {
                this.router.navigate(["event/confirmation"], { queryParams: { id: this.readableEventId } });
            },
            error => {
                console.log(error)
                if(error.status === 500 && error.error === 'Registration already exists') {
                    this.router.navigate(["event/confirmation"], { queryParams: { id: this.readableEventId } });
                } else {
                    this.router.navigate([EventCheckoutRoutePaths.REGISTRATION], { queryParams: { id: this.readableEventId } });
                }
            }
        );
    }

    public chooseRoute () {
        if (!this.showSessions) {
            if (this.showSessionTracks) {
                this.router.navigate(["event/session-tracks"], { queryParams: { id: this.readableEventId } });
            } else if (this.showSpeakers) {
                this.router.navigate(["event/speakers"], { queryParams: { id: this.readableEventId } });
            } else if (this.showPasses) {
                this.router.navigate(["event/pass-information"], { queryParams: { id: this.readableEventId } });
            }
        } else {
            this.router.navigate(["event/sessions"], { queryParams: { id: this.readableEventId } });
        }
    }

    public formatStartDate() : string {
        let dateSettings = DateFormatter.getDateSettings();

        if(dateSettings.convertToLocalDate){
            return DateFormatter.formatDate(this.datePipe, this.event.startDateUTC);
        }
        else{
            return DateFormatter.formatDate(this.datePipe, this.event.startDate, this.event.timeZone);
        }
    }

    public getBannerImage() {
        if (this.event == null) {
            // This early exit avoids showing placeholder image while event isn't loaded.
            return '';
        }

        if (this.event.image != null) {
            return this.event.image;
        } else {
            if (environment.useRestStack === true) {
                return this.imageHelper.getImageUrl(this.defaultImageUrlSelfHosted);
            } else {
                return this.defaultImageUrlCrmHosted
            }
        }
    }

    public registerForSessions() {
        if (this.sessionCartService.getSessionCartForEvent(this.readableEventId).length !== 0) {
            this.router.navigate([EventCheckoutRoutePaths.REGISTRATION], { queryParams: { id: this.readableEventId } });
        } else {
            this.registerForAllSessions();
        }
    }

    public canUserRegister() {
        if (this.event.setRegistrationEndDate) {
            return  new Date(Date.now()) < new Date(this.event.stopRegistrationDate);
        }
        return true;
    }

    private registerForAllSessions() {
        this.sessions.forEach(session => {
            this.sessionCartService.addSession(this.readableEventId, session.id);
        });

        this.sessionCartService.isOpen = true;
    }

    private async getEventAndSessionData(): Promise<void> {
        try {
            this.event = await this.activeEventService.getEvent(this.readableEventId).toPromise();

            this.sessions = await this.activeEventService.getSessions(this.readableEventId).toPromise();
            this.showSessions = this.sessions && this.sessions.length > 0;
            this.chooseRoute();
            this.formattedDate = this.formatStartDate();
        } catch (error) {
            this.error = error;
            console.error(error);
        }
    }

    private async setIsSessionCartAllowed(): Promise<void> {
        const isFreeEvent = await this.activeEventService.isFreeEvent(this.readableEventId);
        const hasSessions = await this.activeEventService.hasEventSessions(this.readableEventId);

        this.isSessionCartAllowed = this.sessionCartService.isSessionCartEnabledForEvent(
            this.event,
            isFreeEvent,
            hasSessions
        );
    }
}


export enum EventQueryParameterNames {
    ReadableEventId = "id",
    SpeakerId = "speakerId"
}

export enum EventDetailSections {
    Sessions = "sessions",
    SessionTracks = "session-tracks",
    Speakers = "speakers",
    PassInformation = "pass-information"
}
